<template>
    <div>
        <div class="form-control">
                <label for="cf_cpf">CPF*:</label>
                <input id="cf_cpf" name="cf_cpf" v-model="cpf" v-mask="'###.###.###-##'" required="true">
            </div>
            <div class="form-control">
                <label for="cf_pis_pasep">PIS/PASEP:</label>
                <input id="cf_pis_pasep" name="cf_pis_pasep" maxlength="11" >
            </div>
            <div class="form-control">
                <label for="cf_data_de_cadastro_pis_pasep">Data de cadastro - PIS/PASEP:</label>
                <input id="cf_data_de_cadastro_pis_pasep" name="cf_data_de_cadastro_pis_pasep" type="date" >
            </div>
            <div class="form-control">
                <label for="cf_carteira_de_trabalho">Carteira de trabalho*:</label>
                <input id="cf_carteira_de_trabalho" name="cf_carteira_de_trabalho" required="true">
            </div>
            <div class="form-control">
                <label for="cf_serie_ctps">Série - CTPS*:</label>
                <input id="cf_serie_ctps" name="cf_serie_ctps" required="true">
            </div>
            <div class="form-control">
                <label for="cf_estado_de_emissao_ctps">Estado de emissão - CTPS*:</label>
                <select name="cf_estado_de_emissao_ctps" id="cf_estado_de_emissao_ctps" required="true">
                    <option value=""></option>
                    <option :value="uf.sigla" v-for="(uf, index) in $store.state.ufs" v-bind:key="index">{{uf.sigla}} - {{uf.nome}}</option>                
                </select>
            </div>
            <div class="form-control">
                <label for="cf_data_de_emissao_ctps">Data de emissão - CTPS*:</label>
                <input id="cf_data_de_emissao_ctps" name="cf_data_de_emissao_ctps" type="date" required="true">
            </div>
            <div class="form-control">
                <label for="cf_titulo_de_eleitor">Título de eleitor:</label>
                <input id="cf_titulo_de_eleitor" name="cf_titulo_de_eleitor">
            </div>
            <div class="form-control">
                <label for="cf_zona_titulo_de_eleitor">Zona - título de eleitor:</label>
                <input id="cf_zona_titulo_de_eleitor" name="cf_zona_titulo_de_eleitor">
            </div>
            <div class="form-control">
                <label for="cf_secao_titulo_de_eleitor">Seção - título de eleitor:</label>
                <input id="cf_secao_titulo_de_eleitor" name="cf_secao_titulo_de_eleitor">
            </div>
            <div class="form-control">
                <label for="cf_cidade_titulo_de_eleitor">Cidade - título de eleitor:</label>
                <input id="cf_cidade_titulo_de_eleitor" name="cf_cidade_titulo_de_eleitor">
            </div>
            <div class="form-control">
                <label for="cf_estado_titulo_de_eleitor">Estado - título de eleitor:</label>
                <select name="cf_estado_titulo_de_eleitor" id="cf_estado_titulo_de_eleitor">
                    <option value=""></option>
                    <option :value="uf.sigla" v-for="(uf, index) in $store.state.ufs" v-bind:key="index">{{uf.sigla}} - {{uf.nome}}</option>                
                </select>
            </div>
            <div class="form-control">
                <label for="cf_identidadea">Identidade*:</label>
                <input id="cf_identidadea" name="cf_identidadea">
            </div>
            <div class="form-control">
                <label for="cf_orgao_expedidor_identidade">Órgão expedidor - identidade*:</label>
                <input id="cf_orgao_expedidor_identidade" name="cf_orgao_expedidor_identidade" required="true">
            </div>
            <div class="form-control">
                <label for="cf_estado_identidade">Estado - identidade*:</label>
                <select name="cf_estado_identidade" id="cf_estado_identidade" required="true">
                    <option value=""></option>
                    <option :value="uf.sigla" v-for="(uf, index) in $store.state.ufs" v-bind:key="index">{{uf.sigla}} - {{uf.nome}}</option>
                </select>
            </div>
            <div class="form-control">
                <label for="cf_data_de_emissao_identidade">Data de emissão - identidade*:</label>
                <input id="cf_data_de_emissao_identidade" name="cf_data_de_emissao_identidade" type="date" required="true">
            </div>
            <div class="form-control">
                <label for="cf_identidade_tem_validade">Identidade tem validade?*:</label>
                <input id="cf_identidade_tem_validade" name="cf_identidade_tem_validade" required="true">
            </div>
            <div class="form-control">
                <label for="cf_certificado_de_reservista">Certificado de reservista:</label>
                <input id="cf_certificado_de_reservista" name="cf_certificado_de_reservista">
            </div>
            <div class="form-control">
                <label for="cf_serie_reservista">Série - reservista:</label>
                <input id="cf_serie_reservista" name="cf_serie_reservista">
            </div>
            <div class="form-control">
                <label for="cf_categoria_reservista">Categoria - reservista:</label>
                <input id="cf_categoria_reservista" name="cf_categoria_reservista">
            </div>
            <div class="form-control">
                <label for="cf_regiao_militar_reservista">Região militar - reservista:</label>
                <input id="cf_regiao_militar_reservista" name="cf_regiao_militar_reservista">
            </div>
            <!-- <div class="form-control">
                <label for="cf_carteira_de_motorista">Carteira de motorista:</label>
                <input id="cf_carteira_de_motorista" name="cf_carteira_de_motorista">
            </div> -->
            <!-- <div class="form-control">
                <label for="cf_categoria_cnh">Categoria - CNH:</label>
                <input id="cf_categoria_cnh" name="cf_categoria_cnh">
            </div>
            <div class="form-control">
                <label for="cf_data_de_vencimento_cnh">Data de vencimento - CNH:</label>
                <input id="cf_data_de_vencimento_cnh" name="cf_data_de_vencimento_cnh" type="date">
            </div> -->
            <div class="form-control">
                <label for="cf_possui_cartao_do_sus">Possui cartão do SUS?*:</label>
                <select name="cf_possui_cartao_do_sus" id="cf_possui_cartao_do_sus" required="true">
                    <option value=""></option>
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                </select>
            </div>
            <div class="form-control">
                <label for="cf_estrangeiro">Estrangeiro?:</label>
                <select name="cf_estrangeiro" id="cf_estrangeiro" required="true" v-model="cf_estrangeiro">
                    <option value="sim">Sim</option>
                    <option value="nao" selected>Não</option>
                </select>
            </div>
            <div class="form-control" v-if="cf_estrangeiro == 'sim'">
                <label for="cf_numero_rne">Número RNE:</label>
                <input id="cf_numero_rne" name="cf_numero_rne">
            </div>
            <div class="form-control" v-if="cf_estrangeiro == 'sim'">
                <label for="cf_naturalizado">Naturalizado?:</label>
                <select name="cf_naturalizado" id="cf_naturalizado" required="true">
                    <option value=""></option>
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                </select>
            </div>
            <div class="form-control" v-if="cf_estrangeiro == 'sim'">
                <label for="cf_tipo_visto">Tivo de visto:</label>
                <input id="cf_tipo_visto" name="cf_tipo_visto" required="true">
            </div>
            <div class="form-control" v-if="cf_estrangeiro == 'sim'">
                <label for="cf_data_chegada_pais">Data de chegada ao país:</label>
                <input id="cf_data_chegada_pais" name="cf_data_chegada_pais" type="date" required="true">
            </div>
            <div class="form-control" v-if="cf_estrangeiro == 'sim'">
                <label for="cf_data_emissao_visto">Data de emissão do visto:</label>
                <input id="cf_data_emissao_visto" name="cf_data_emissao_visto" type="date" required="true">
            </div>
            <div class="form-control" v-if="cf_estrangeiro == 'sim'">
                <label for="cf_data_validade_visto">Data de validade do visto:</label>
                <input id="cf_data_validade_visto" name="cf_data_validade_visto" type="date" required="true">
            </div>
    </div>
</template>
<script>
import {mask} from 'vue-the-mask'
export default {
    directives: {mask},
    created(){
        // this.cpf = this.$route.params.key;
    },
    data: () => ({
        cpf: null,
        cf_estrangeiro: ""
	}),
}
</script>