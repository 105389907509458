var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"CandidatoForm"}},[_c('div',{staticClass:"box-loading"},[(_vm.$store.state.loading)?_c('div',{staticClass:"loading"},[_c('img',{attrs:{"src":require("@/assets/loading.gif"),"alt":"Carregando...","title":"Carregando..."}})]):_vm._e(),_c('h2',[_vm._v("Formulário de admissão")])]),_c('form',{on:{"submit":_vm.submit}},[(_vm.role == 'dp')?_c('div',[_c('h3',[_vm._v("Dados DP")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e(),_c('h3',[_vm._v("Dados Pessoais")]),_c('InputDadosPessoais',{attrs:{"form":_vm.form.dados_pessoais}}),_c('h2',[_vm._v("Dados de endereço")]),_c('InputDadosEndereco'),_c('h2',[_vm._v("Dados de documentação")]),_c('InputDadosDocumentacao'),_c('h2',[_vm._v("Dados bancários")]),_c('InputDadosBancarios'),_c('h2',[_vm._v("Dependentes Legais")]),_c('div',{staticClass:"form-control"},[_c('label',{attrs:{"for":"cf_count_dependentes"}},[_vm._v("Dependentes:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.count_dependentes),expression:"count_dependentes"}],attrs:{"type":"range","id":"cf_count_dependentes","name":"cf_count_dependentes","min":"0","max":"10"},domProps:{"value":(_vm.count_dependentes)},on:{"change":function($event){return _vm.add_dependente()},"__r":function($event){_vm.count_dependentes=$event.target.value}}}),_c('span',[_vm._v(_vm._s(_vm.count_dependentes))])]),_c('div',{staticClass:"box_dependentes"},_vm._l((_vm.dependentes),function(input,index){return _c('InputDependente',{key:index,attrs:{"id-data":index,"dependente":input.dependente}})}),1),_vm._m(6)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('label',{attrs:{"for":"cf_CTURNO"}},[_vm._v("Turno Trab:")]),_c('input',{attrs:{"id":"cf_CTURNO","name":"cf_CTURNO","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('label',{attrs:{"for":"cf_CTCREGRA"}},[_vm._v("Regra Apont:")]),_c('input',{attrs:{"id":"cf_CREGRA","name":"cf_CREGRA","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('label',{attrs:{"for":"cf_CCSEQTU"}},[_vm._v("Seq. Ini. Turn:")]),_c('input',{attrs:{"id":"cf_CSEQTU","name":"cf_CSEQTU","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('label',{attrs:{"for":"cf_CBHFOL"}},[_vm._v("B.H p/ Folha:")]),_c('select',{attrs:{"id":"cf_CBHFOL","name":"cf_CBHFOL","required":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Selecione uma opção")]),_c('option',{attrs:{"value":"S"}},[_vm._v("SIM")]),_c('option',{attrs:{"value":"N"}},[_vm._v("NAO")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('label',{attrs:{"for":"cf_CACULBH"}},[_vm._v("Acum.B.Horas:")]),_c('select',{attrs:{"id":"cf_CACULBH","name":"cf_CACULBH","required":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Selecione uma opção")]),_c('option',{attrs:{"value":"S"}},[_vm._v("SIM")]),_c('option',{attrs:{"value":"N"}},[_vm._v("NAO")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('label',{attrs:{"for":"cf_CTPJORNA"}},[_vm._v("Tp Jorn Trab:")]),_c('select',{attrs:{"id":"cf_CTPJORNA","name":"cf_CTPJORNA","required":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Selecione uma opção")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1 - Submetidos a Horario de Trabalho")]),_c('option',{attrs:{"value":"2"}},[_vm._v("2 - Atividade Externa especificada")]),_c('option',{attrs:{"value":"3"}},[_vm._v("3 - Funções especificadas")]),_c('option',{attrs:{"value":"4"}},[_vm._v("4 - Teletrabalho")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control"},[_c('button',{attrs:{"type":"submit"}},[_vm._v("Enviar formulário")])])
}]

export { render, staticRenderFns }