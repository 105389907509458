<template>
    <div>        
        <div class="form-control">
            <label for="cf_cep">CEP*:</label>
            <input id="cf_cep" name="cf_cep" v-mask="'#####-###'" required="true">
        </div>
        <div class="form-control">
            <label for="cf_endereco">Endereço*:</label>
            <input id="cf_endereco" name="cf_endereco" required="true">
        </div>
        <div class="form-control">
            <label for="cf_numero">Número*:</label>
            <input id="cf_numero" name="cf_numero" required="true">
        </div>
        <div class="form-control">
            <label for="cf_complemento">Complemento:</label>
            <input id="cf_complemento" name="cf_complemento">
        </div>
        <div class="form-control">
            <label for="cf_bairro">Bairro*:</label>
            <input id="cf_bairro" name="cf_bairro" required="true">
        </div>
        <div class="form-control">
            <label for="cf_estado">Estado*:</label>
             <select name="cf_estado" id="cf_estado" @change="load_municipios" required="true">
                <option value=""></option>
                <option :value="uf.sigla" v-for="(uf, index) in ufs" v-bind:key="index">{{uf.sigla}} - {{uf.nome}}</option>                
            </select>
        </div>
        <div class="form-control">
            <label for="cf_cidade">Cidade*:</label>
            <select name="cf_cidade" id="cf_cidade">
                <option value="">Selecione um estado primeiro</option>
                <option :value="municipio.id" v-for="(municipio, index) in municipios" v-bind:key="index">{{municipio.nome}}</option>                
            </select>
        </div>
        <div class="form-control">
            <label for="cf_telefone_pessoal">Telefone pessoal*:</label>
            <input id="cf_telefone_pessoal" name="cf_telefone_pessoal" v-mask="['(##) ####-####', '(##) #####-####']" type="tel" required="true">
        </div>
        <div class="form-control">
            <label for="cf_telefone_celular">Telefone celular:</label>
            <input id="cf_telefone_celular" name="cf_telefone_celular" v-mask="['(##) ####-####', '(##) #####-####']" type="tel">
        </div>
    </div>
</template>
<script>
import {mask} from 'vue-the-mask'
import mixins from '@/assets/mixins'

export default {
    directives: {mask},
    mixins: [mixins],
    created(){
    },
    data: () => ({
        ufs: null,
        municipios: null
    }),
    mounted () {
        //Carrega os estados
       var interval = setInterval(() => {
            this.ufs = this.$store.state.ufs;
                        
            if(this.ufs != null){
                clearInterval(interval);
            } 
        }, 1000)

       var interval2 = setInterval(() => {
            this.municipios = this.$store.state.municipios;
                        
            if(this.municipios != null){
                clearInterval(interval2);
            } 
        }, 1000)
    },
    methods:{
        load_municipios(el){

            var uf = el.target.value;
            var id_uf = null;

            //Loop in all uf's to find id uf
            for (const el_uf in this.ufs) {
                if(uf == this.ufs[el_uf].sigla){
                    id_uf = this.ufs[el_uf].id;
                }
            }

            if(!id_uf){
                alert('Erro ao carregar as uf\'s');
                return false;
            }


            this.axios
                .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id_uf}/municipios`)
                .then(response => {

                    //Check if status that's ok!
                    if(response.status == 200){
                        this.municipios = this.orderStr(response.data, 'nome');
                    }else{
                        alert('Erro ao carregar as uf\'s');
                    }
                })
        }
    }

}
</script>

